import Color from "color"

const BASE_COLORS = {
  MAIN: "#0F5E60",
  BRIGHT: "#F9D50C",
  CLICKABLE: "#A12D4C",

  SUCCESS: "#35B668",
  ERROR: "#C93534",

  WHITE: "#FFFFFF",
}

const COLORS = {
  ...BASE_COLORS,
  CLICKABLE_HOVER: Color(BASE_COLORS.CLICKABLE).lighten(0.2).hex(),
}

export default COLORS
