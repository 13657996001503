import { useCallback, useEffect, useRef, useState } from "react"
import useGameSize, { MAX_NARROWER, MAX_WIDER } from "./useGameSize"

function usePositions(basePositions, isRelative = true) {
  const { isVertical } = useGameSize()
  const basePositionsR = useRef(
    basePositions.map((p, index) => {
      if (!isRelative) {
        return p
      } else {
        const isHorizontalPosition = index % 2 === 0

        return isHorizontalPosition
          ? [p[0] * MAX_WIDER, p[1] * MAX_NARROWER]
          : [p[0] * MAX_NARROWER, p[1] * MAX_WIDER]
      }
    })
  )

  const getCurrentPositions = useCallback(() => {
    const index = isVertical ? 1 : 0

    return basePositionsR.current.map((v) => v[index])
  }, [isVertical])

  const [positions, setPositions] = useState(getCurrentPositions())

  useEffect(() => {
    setPositions(getCurrentPositions())
  }, [getCurrentPositions])

  return positions
}

export default usePositions
