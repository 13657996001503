import React, { useEffect, useMemo, useRef, useState } from "react"

import Element from "../../components/Element"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import FullStage from "../../components/FullStage"
import { Group, Layer } from "react-konva"

import PuzzleAnswer from "./subcomponents/PuzzleAnswer"
import PuzzleImage, { MISSING_PIECE_ID } from "./subcomponents/PuzzleImage"
import "./PuzzleExercise.scss"
import { some, chain } from "lodash"
import { useMap } from "react-use"
import Sounds from "../../lib/Sounds/Sounds"
import { useQuestionsState } from "../../state/exercise/$questions"

const PuzzleExercise = ({ state, inAnswersState, answers, answerChosen }) => {
  const stage_ref = useRef()
  const {
    current: { parameters },
  } = useQuestionsState()
  const [dragged_answer, setDraggedAnswer] = useState(null)
  const [answered, { set: setAnswered, setAll: setAllAnswered }] = useMap({})
  const [revealed, setRevealed] = useState(false)

  const image = useMemo(() => parameters.image, [parameters])

  useEffect(() => {
    setAllAnswered(chain(answers).keyBy("id").mapValues(false))
  }, [answers, setAllAnswered])

  useEffect(() => {
    if (state === AS.APPEARING) setRevealed(false)
  }, [state])

  const onDragEnd = (e) => {
    const { current: stage } = stage_ref
    const position = stage.getPointerPosition()
    const intersections = stage.getAllIntersections(position)

    if (intersections && some(intersections, { attrs: { id: MISSING_PIECE_ID } })) {
      if (dragged_answer.correct) {
        Sounds.success.play()
        setRevealed(true)
      } else {
        Sounds.error.play()
      }

      setAnswered(dragged_answer.id, true)
      setTimeout(() => answerChosen(dragged_answer), 800)
    }

    setDraggedAnswer(null)
  }

  return (
    <div className="PuzzleExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage stage_ref={stage_ref} onDragEnd={onDragEnd}>
          <Layer>
            <Group>
              <PuzzleImage image={image} revealed={revealed} />
              {answers?.map((answer, index) => (
                <PuzzleAnswer
                  key={answer.id}
                  answer={answer}
                  answered={answered[answer.id]}
                  order={index}
                  count={answers?.length}
                  answerChosen={answerChosen}
                  setDraggedAnswer={setDraggedAnswer}
                />
              ))}
            </Group>
          </Layer>
        </FullStage>
      </Element>
    </div>
  )
}

export default PuzzleExercise
