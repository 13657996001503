import React from "react";
import _, { defaultTo, shuffle } from "lodash";
import Velocity from "velocity-animate";

import PlayArea from "lib/PlayArea";
import RiseFallCard from "./subcomponents/RiseFallCard";
import { emString } from "utils/styling";
import Timer from "utils/Timer";
import Sounds from "lib/Sounds";

import "./RiseFallExercise.scss";
import Button from "components/Button/Button";
import FeedbackCard from "components/FeedbackCard/FeedbackCard";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";

import cloud1Img from "./img/cloud-1.svg";
import cloud2Img from "./img/cloud-2.svg";
import cloud3Img from "./img/cloud-3.svg";
import ExerciseComponent, {
  DEFAULT_STATES,
} from "../../base/ExerciseComponent";
import { INSTRUCTION_STEP_TYPES } from "../../base/subcomponents";
import exerciseImg from "./img/exercise.jpg";
import exerciseVerticalImg from "./img/exercise-vertical.jpg";
import { withTranslation } from "react-i18next";
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import CONFIG from "../../config";

export const ANSWERS_HEIGHT_HORIZONTAL_EMS = 95;
export const ANSWERS_HEIGHT_VERTICAL_EMS = 110;
export const ANSWER_WIDTH_EMS = 26;
export const ANSWER_HEIGHT_EMS = 18;

// export const START_POSITIONS_HORIZONTAL= [0, (PlayArea.widthInEms() - ANSWER_WIDTH_EMS) / 2, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];
// export const START_POSITIONS_VERTICAL= [0, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];

export const MOVEMENT_HORIZONTAL =
  ANSWERS_HEIGHT_HORIZONTAL_EMS - ANSWER_HEIGHT_EMS;
export const MOVEMENT_VERTICAL =
  ANSWERS_HEIGHT_VERTICAL_EMS - ANSWER_HEIGHT_EMS;

export const ANIMATION_SPEED = 1000;
export const FLIGHT_DURATION = 17000;
// export const FLIGHT_DURATION = 30000;

const FEEDBACK_DURATION_MS = 6000;
const DEFAULT_TIME_LIMIT_S = 90;

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_SHOWING_FEEDBACK: 3,
  QUESTION_ANSWERED: 4,
  QUESTION_HIDING: 5,
};

const QUESTION_ACTIVE_STATES = [
  STATES.QUESTION_APPEARING,
  STATES.QUESTION_ANSWERING,
  STATES.QUESTION_SHOWING_FEEDBACK,
];

class RiseFallExercise extends ExerciseComponent {
  static exerciseClass = "ParachutesExercise";

  timePerQuestionSeconds;
  questionIndex = 0;
  answers = [];

  initInstructions(props) {
    const { t } = props;

    this.instruction = t("game_instruction");
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    };
  }

  static maxPoints(questions) {
    let points = 0;

    for (const question of questions) {
      points += question.answers.length - 1;
    }

    return points;
  }

  chosenAnswerIds = [];
  timeout = null;

  nextAnswerTimeout;
  cloudsTimeout;
  visibleAnswersCount = 0;
  lastAnswerPopDate = new Date();
  flightDuration = FLIGHT_DURATION;
  timeBetweenAnswers = FLIGHT_DURATION / 3;

  startPositions = [0];
  currentPoints = 0;

  lastAnswerPosition;

  constructor(props) {
    super(props);

    const { questions, parameters } = props;

    this.timePerQuestionSeconds = defaultTo(
      parameters["timePerQuestionSeconds"],
      DEFAULT_TIME_LIMIT_S
    );
    this.maxPoints = RiseFallExercise.maxPoints(questions);

    this.state = {
      ...this.state,

      question: {},
      answers: [],

      currentQuestionIndex: 0,
      currentAnswerIndex: 0,

      feedback: {
        isCorrect: false,
        message: "",
      },
    };

    this.cloudRefs = {
      1: React.createRef(),
      2: React.createRef(),
      3: React.createRef(),
    };
  }

  componentDidMount() {
    this.calculateStartPositions();
    window.addEventListener("resize", this.calculateStartPositions);
  }

  calculateStartPositions = () => {
    if (PlayArea.isHorizontal()) {
      this.startPositions = [
        0,
        (PlayArea.widthInEms() - ANSWER_WIDTH_EMS) / 2,
        PlayArea.widthInEms() - ANSWER_WIDTH_EMS,
      ];
    } else {
      this.startPositions = [0, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];
    }
  };

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.QUESTION_ANSWERING],
        },
        questions,
        parameters
      ),
    ];
  }

  answerChosen = (chosenAnswer, index) => {
    super._answerChosen(chosenAnswer);

    let pointChange = 0;
    let isCorrect;

    this.chosenAnswerIds.push(chosenAnswer.id);

    if (chosenAnswer.correct) {
      if (chosenAnswer.parking) {
        this.currentPoints--;
        pointChange = 1;
      } else {
        pointChange = this.currentPoints;
      }
      isCorrect = true;
      Sounds.success.play();
    } else {
      this.currentPoints--;
      isCorrect = false;
      Sounds.error.play();
    }

    let questionFinished = false;
    let timeForFeedback = 0;

    this.setState(
      (state) => {
        let feedback = chosenAnswer.feedback;

        let points = state.points + pointChange;
        let timeout = new Timer(
          this.hideAnswer.bind(this, index),
          this.flightDuration / 10
        );

        let newAnswers = [...state.answers];

        newAnswers[index] = {
          ...state.answers[index],
          active: false,
          showFeedback: true,
          moving: false,
          timeout: timeout,
        };

        if (feedback) {
          timeForFeedback = FEEDBACK_DURATION_MS;
        } else {
          timeForFeedback = CONFIG.ANIMATION_SPEED_MS;
        }

        if (chosenAnswer.correct && !chosenAnswer.parking) {
          questionFinished = true;
        }

        return {
          points,
          answers: newAnswers,

          feedback: {
            isCorrect,
            message: feedback,
          },
        };
      },
      () => {
        if (questionFinished) {
          this.setCurrentState(
            STATES.QUESTION_SHOWING_FEEDBACK,
            () => {
              if (this.isLastQuestionShown()) {
                this.setCurrentStateSequence(
                  [STATES.QUESTION_ANSWERED, DEFAULT_STATES.FINISHING],
                  CONFIG.ANIMATION_SPEED_MS
                );
              } else {
                this.setCurrentState(STATES.QUESTION_ANSWERED);
              }
            },
            timeForFeedback
          );
        } else {
          this.setCurrentStateSequence(
            [STATES.QUESTION_SHOWING_FEEDBACK, STATES.QUESTION_ANSWERING],
            timeForFeedback,
            () => {
              if (this.visibleAnswersCount > 1) {
                this.showNextAnswerIn(
                  this.timeBetweenAnswers -
                    (new Date() - this.lastAnswerPopDate)
                );
              } else {
                this.popNextAnswer();
              }
            }
          );
        }
      }
    );
  };

  continue = () => {
    this.setCurrentState(
      STATES.QUESTION_HIDING,
      () => {
        this.setState(
          (state) => ({
            currentQuestionIndex: state.currentQuestionIndex + 1,
            currentAnswerIndex: 0,
          }),
          () => {
            this.showQuestion();
          }
        );
      },
      CONFIG.ANIMATION_SPEED_MS
    );
  };

  showNextAnswerIn = (timeoutMS) => {
    clearTimeout(this.nextAnswerTimeout);
    this.nextAnswerTimeout = setTimeout(this.popNextAnswer, timeoutMS);
  };

  popNextAnswer = () => {
    if (this.inState(STATES.QUESTION_ANSWERING)) {
      let newIndex = this.state.currentAnswerIndex;
      let answers = this.state.answers;

      while (answers[newIndex].visible || !answers[newIndex].active) {
        newIndex = this._nextAnswerIndex(newIndex);

        if (newIndex === this.state.currentAnswerIndex) {
          // No inactive and not visible answers
          break;
        }
      }

      if (!answers[newIndex].visible && answers[newIndex].active) {
        if (answers[newIndex].timeout) {
          clearTimeout(answers[newIndex].timeout);
        }

        this.setState((prevState) => {
          let timeout = new Timer(
            this.hideAnswer.bind(this, newIndex),
            this.flightDuration - this.flightDuration / 10
          );

          prevState.answers[newIndex].visible = true;
          prevState.answers[newIndex].moving = true;
          prevState.answers[newIndex].timeout = timeout;
          prevState.answers[newIndex].left = this._getNextPosition();

          return {
            answers: prevState.answers,
            currentAnswerIndex: this._nextAnswerIndex(
              prevState.currentAnswerIndex
            ),
          };
        });
      }

      this.visibleAnswersCount++;
      this.lastAnswerPopDate = new Date();
      this.showNextAnswerIn(this.timeBetweenAnswers);
    }
  };

  hideAnswer = (answerIndex) => {
    this.visibleAnswersCount--;
    this.setState((prevState) => {
      let timeout = new Timer(
        this.stopMovingAnswer.bind(this, answerIndex),
        this.flightDuration / 10
      );
      prevState.answers[answerIndex].visible = false;
      prevState.answers[answerIndex].timeout = timeout;

      return {
        answers: prevState.answers,
      };
    });
  };

  stopMovingAnswer = (answerIndex) => {
    this.setState((prevState) => {
      prevState.answers[answerIndex].moving = false;

      return {
        answers: prevState.answers,
      };
    });
  };

  _nextAnswerIndex = (oldIndex) => {
    let newIndex = oldIndex + 1;

    if (newIndex >= this.state.answers.length) {
      newIndex = 0;
    }

    return newIndex;
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateStartPositions);
    clearTimeout(this.nextAnswerTimeout);
    clearTimeout(this.cloudsTimeout);
  }

  startGame = () => {
    this.showQuestion();
    this.cloudsTimeout = setTimeout(() => this._startClouds(), 1000);
  };

  showQuestion = () => {
    const { questions } = this.props;
    let question;

    this.setState(
      (state) => {
        for (let answer of state.answers) {
          if (answer.timeout) {
            answer.timeout.clear();
          }
        }

        question = questions[state.currentQuestionIndex];
        const answers = this.prepareAnswersForQuestion(question);
        this.visibleAnswersCount = 0;
        this.currentPoints = answers.length - 1;

        return {
          question,
          answers,

          currentAnswerIndex: 0,
        };
      },
      () => {
        this.setCurrentStateSequence(
          [STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING],
          CONFIG.ANIMATION_SPEED_MS,
          () => {
            this._questionAppeared(question);
            this.popNextAnswer();
          }
        );
      }
    );
  };

  _startClouds = () => {
    Velocity(
      this.cloudRefs[1].current,
      {
        translateX: [emString(PlayArea.widthInEms() + 70), 0],
      },
      {
        duration: "80000",
        loop: true,
      }
    );
    Velocity(
      this.cloudRefs[2].current,
      {
        translateX: [emString(PlayArea.widthInEms() + 60), 0],
      },
      {
        duration: "80000",
        loop: true,
      }
    );
    Velocity(
      this.cloudRefs[3].current,
      {
        translateX: [emString(PlayArea.widthInEms() + 50), 0],
      },
      {
        duration: "80000",
        loop: true,
      }
    );
  };

  prepareAnswersForQuestion = (question) => {
    let answers = [...question.answers];

    return shuffle(answers).map((answer) => {
      return {
        id: answer.id,

        visible: false,
        active: true,
        moving: false,
        showFeedback: false,

        content: answer.content,
        correct: answer.correct,
        feedback: answer.parameters.feedback,
        parking: answer.parameters.parking,

        left: 0,
        falling: false,
        timeout: null,
      };
    });
  };

  _getAnswersContainerHeight = () => {
    if (PlayArea.isVertical()) {
      return ANSWERS_HEIGHT_VERTICAL_EMS;
    } else {
      return ANSWERS_HEIGHT_HORIZONTAL_EMS;
    }
  };

  _getNextPosition = () => {
    let newPosition;

    do {
      newPosition = _.sample(this.startPositions);
    } while (newPosition === this.lastAnswerPosition);

    this.lastAnswerPosition = newPosition;
    return newPosition;
  };

  isLastQuestionShown = () => {
    const { questions } = this.props;
    const { currentQuestionIndex } = this.state;

    return currentQuestionIndex === questions.length - 1;
  };

  renderExercise(state, props) {
    const { t } = props;
    const { question, answers, feedback } = state;

    let answersComponent = answers.map((answer, i) => {
      let style = {
        bottom: 0,
        left: emString(answer.left),

        opacity: answer.visible ? "1" : "0",
        zIndex: answer.visible ? "3" : "0",

        transition: `opacity ${this.flightDuration / 10}ms ease-in-out`,
      };

      let shouldMove =
        answer.moving && !this.inState(STATES.QUESTION_SHOWING_FEEDBACK);

      return (
        <RiseFallCard
          key={answer.id}
          answer={answer}
          clickCallback={this.answerChosen}
          moving={shouldMove}
          style={style}
          index={i}
        />
      );
    });

    let answersContainerStyle = {
      height: emString(this._getAnswersContainerHeight()),
    };

    return (
      <AnimatedElement
        className="RiseFallExercise"
        visible={
          !this.inStates([STATES.STARTING, STATES.FINISHING, STATES.FINISHED])
        }
      >
        <div className={"clouds-container"}>
          <img
            className={"cloud"}
            id={"cloud-1"}
            ref={this.cloudRefs[1]}
            src={cloud1Img}
            alt="Cloud"
          />
          <img
            className={"cloud"}
            id={"cloud-2"}
            ref={this.cloudRefs[2]}
            src={cloud2Img}
            alt="Cloud"
          />
          <img
            className={"cloud"}
            id={"cloud-3"}
            ref={this.cloudRefs[3]}
            src={cloud3Img}
            alt="Cloud"
          />
        </div>
        <InstructionCard
          visible={this.inStates(QUESTION_ACTIVE_STATES)}
          mainText={question.content}
          markdown
          small
        />
        <AnimatedElement
          className="answers-container"
          visible={this.inState(QUESTION_ACTIVE_STATES)}
        >
          <div style={answersContainerStyle}>{answersComponent}</div>
        </AnimatedElement>
        <AnimatedElement
          className="container-next"
          visible={this.inStates(STATES.QUESTION_ANSWERED)}
          animation={AnimatedElement.AnimationTypes.popOut}
          appearDelayMs={2000}
        >
          <Button onClick={this.continue} big>
            {t("common:nextQuestion")}
          </Button>
        </AnimatedElement>
        <FeedbackCard
          visible={this.inState(STATES.QUESTION_SHOWING_FEEDBACK)}
          content={feedback.message}
          successful={feedback.isCorrect}
          useDefaultFeedback={false}
        />
      </AnimatedElement>
    );
  }

  timeRanOut = () => {
    if (this.isLastQuestionShown()) {
      this.setCurrentStateSequence(
        [STATES.QUESTION_ANSWERED, DEFAULT_STATES.FINISHING],
        CONFIG.ANIMATION_SPEED_MS
      );
    } else {
      this.setCurrentState(STATES.QUESTION_ANSWERED);
    }
  };

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING);
  };
}

export default withTranslation(["exercises/balloon", "common"])(
  RiseFallExercise
);
