import imgHorizontal from "images/exercises/conversations/exercise-horizontal.jpg"
import imgVertical from "images/exercises/conversations/exercise-vertical.jpg"
import { NEXT_QUESTION_TYPE, QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"

const conversations_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    questions: {
      show: false,
      next: NEXT_QUESTION_TYPE.IN_ANSWER,
    },
    answers: {
      sounds: true,
      finish_type: QUESTION_FINISH_TYPES.FIRST,
    },
    points: {
      per_correct_answer: 2,
    },
  },
}

export default conversations_config
