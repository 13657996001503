import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from "react-transition-group";

import './css/Animation.css';
const ANIMATION_SPEED = 600;

export const ANIMATION_TYPES = {
  fade: 'fade',
};

export default class Animation extends Component {
  static propTypes = {
    'className': PropTypes.string,
    'type': PropTypes.string,
    'active': PropTypes.bool,
    'delay_ms': PropTypes.number
  };

  static defaultProps = {
    'type': 'fade',
    'active': true,
    'delay_ms': 0,
  };

  timeout;

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    }
  }

  static TYPES = {
    fade: 'fade',
  };

  componentDidMount() {
    this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      clearTimeout(this.timeout);

      if (this.props.active) {
        this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
      } else {
        this.updateVisibility(this.props.active)
      }
    } else if ((prevProps.id !== this.props.id) && this.props.active) {
      clearTimeout(this.timeout)
      this.updateVisibility(false)
      this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
    }
  }

  updateVisibility = (visible) => {
    this.setState({show: visible})
  };

  render() {
    const {children} = this.props
    const items = React.Children.map(children, (child, index) => (
      <CSSTransition key={index}
        classNames={this.props.type}
        timeout={{enter: ANIMATION_SPEED, exit: ANIMATION_SPEED}}
      >
        {child}
      </CSSTransition>
    ))

    return (
      <TransitionGroup
        className={this.props.className}
      >
         {this.state.show && items}
      </TransitionGroup>
    );
  }
}
