import imgHorizontal from "images/exercises/cleaning/exercise-horizontal.jpg"
import imgVertical from "images/exercises/cleaning/exercise-vertical.jpg"

const cleaning_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
}

export default cleaning_config
