import React from "react"
import { Group, Line, Rect, Text } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import { useTextSize } from "../../../enums/text_size"

const ConversationStarter = ({ content }) => {
  const fontSize = useTextSize("sm")
  const [x, y, width, height] = usePositions([
    [0.01, 0.01],
    [0.16, 0.12],

    [0.7, 0.8],
    [0.15, 0.1],
  ])
  const [x1, y1, x2, y2, x3, y3] = usePositions([
    [0.01, 0.02],
    [0, 0],

    [0.04, 0.05],
    [0, 0],

    [0.002, 0.002],
    [0.03, 0.02],
  ])

  return (
    <Group x={x} y={y} id="ConversationStarter">
      <Rect width={width} height={height} fill={COLORS.MAIN} cornerRadius={0.1 * height} />
      <Text
        x={0.05 * width}
        width={0.9 * width}
        y={0.05 * height}
        height={0.9 * height}
        align="center"
        verticalAlign="middle"
        fill={COLORS.WHITE}
        fontFamily="Lato"
        text={content}
        fontSize={fontSize}
        lineHeight={1.25}
      />
      <Line
        x={0}
        y={0.98 * height}
        fill={COLORS.MAIN}
        points={[x1, y1, x2, y2, x3, y3]}
        closed={true}
      />
    </Group>
  )
}

export default ConversationStarter
