import React from "react"
import clsx from "clsx"

import AnimatedElement from "../AnimatedElement/AnimatedElement"

import "./PointsBar.scss"

const PointsBar = ({
  t,
  showPoints,
  showTime,
  currentPoints,
  maxPoints,
  readableTime,
  isTimeRunningOut,
  isTimeRunning,
  visible,
}) => {
  return (
    <AnimatedElement
      className="PointsBar"
      visible={visible}
      animation={AnimatedElement.AnimationTypes.slideLeft}
    >
      <div className="left-filler" />
      <div
        className={clsx("points-bar-body", { "with-clock": showTime }, { "with-cup": showPoints })}
      >
        <div
          className={clsx("points-bar-with-icon points", {
            "cup-icon": showPoints,
          })}
        >
          {showPoints && (
            <>
              <span className="current-points">{currentPoints}</span>
              {maxPoints && <span className="max-points">{maxPoints}</span>}
            </>
          )}
        </div>
        <div className="points-bar-content">{t("instruction")}</div>
        <div
          className={clsx("points-bar-with-icon", {
            "clock-icon": showTime,
            "clock-warning": isTimeRunningOut,
            "clock-warning-stopped": isTimeRunningOut & !isTimeRunning,
          })}
        >
          {showTime && readableTime}
        </div>
      </div>
    </AnimatedElement>
  )
}

export default PointsBar
