import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Element from "../../components/Element"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import FullStage from "../../components/FullStage"
import { Group, Layer } from "react-konva"
import TrueFalseAnswer, { TRUE_FALSE_ANSWER_STATE } from "./subcomponents/TrueFalseAnswer"
import TrueFalseButtons from "./subcomponents/TrueFalseButtons"
import useCurrentState from "../../hooks/useCurrentState"
import { cloneDeep } from "lodash"

import "./TrueFalseExercise.scss"
import Sounds from "../../lib/Sounds/Sounds"

const TrueFalseExercise = ({ state, inAnswersState, answers, answerChosen }) => {
  const {
    state: answer_state,
    changeStatesInSequence: changeAnswerStatesInSequence,
  } = useCurrentState(TRUE_FALSE_ANSWER_STATE, TRUE_FALSE_ANSWER_STATE.ACTIVE)
  const [answer_index, setAnswerIndex] = useState(0)
  const [answer_correct, setAnswerCorrect] = useState(true)
  const current_answer = useMemo(() => answers[answer_index], [answers, answer_index])
  const stage_ref = useRef()

  const nextAnswer = useCallback(() => {
    if (answer_index + 1 < answers.length) {
      setAnswerIndex(answer_index + 1)
      changeAnswerStatesInSequence(
        [TRUE_FALSE_ANSWER_STATE.APPEARING, TRUE_FALSE_ANSWER_STATE.ACTIVE],
        600
      )
    }
  }, [answers, answer_index, changeAnswerStatesInSequence])

  const innerAnswerChosen = useCallback(
    (is_correct) => {
      let answer = cloneDeep(current_answer)
      setAnswerCorrect(is_correct)
      answer.correct = is_correct
      answer.parameters.feedback = is_correct
        ? answer.parameters.correctFeedback
        : answer.parameters.incorrectFeedback

      setTimeout(() => answerChosen(answer), 250)

      const sound = is_correct ? Sounds.success : Sounds.error
      sound.play()

      if (!answer.parameters.feedback) {
        changeAnswerStatesInSequence(
          [TRUE_FALSE_ANSWER_STATE.INACTIVE, TRUE_FALSE_ANSWER_STATE.DISAPPEARING],
          500,
          nextAnswer,
          1500
        )
      } else {
        changeAnswerStatesInSequence(
          [TRUE_FALSE_ANSWER_STATE.INACTIVE, TRUE_FALSE_ANSWER_STATE.DISAPPEARING],
          500
        )
      }
    },
    [current_answer, answerChosen, changeAnswerStatesInSequence, nextAnswer]
  )

  useEffect(() => {
    if (state === AS.ACTIVE && answer_state === TRUE_FALSE_ANSWER_STATE.DISAPPEARING) {
      nextAnswer()
    }
  }, [state, answer_state, nextAnswer])

  return (
    <div className="TrueFalseExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage stage_ref={stage_ref}>
          <Layer>
            <Group>
              <TrueFalseAnswer
                correct={answer_correct}
                state={answer_state}
                answer={current_answer}
              />
              <TrueFalseButtons answer={current_answer} choose={innerAnswerChosen} />
            </Group>
          </Layer>
        </FullStage>
      </Element>
    </div>
  )
}

export default TrueFalseExercise
