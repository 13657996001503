import React, { useEffect, useRef } from "react"
import { Group, Rect, Text } from "react-konva"
import Konva from "konva"
import useHVPosition from "../../../hooks/screen/useHVPosition"
import COLORS from "../../../enums/colors"

export const TRUE_FALSE_ANSWER_STATE = {
  APPEARING: "APPEARING",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DISAPPEARING: "DISAPPEARING",
}

const S = { ...TRUE_FALSE_ANSWER_STATE }

const TrueFalseAnswer = ({ state, answer, correct }) => {
  const appearing_coords = useHVPosition({
    x: [-0.6, -0.6],
  })
  const { x, y, width, height } = useHVPosition({
    x: [0.2, 0.2],
    y: [0.4, 0.4],
    width: [0.6, 0.6],
    height: [0.2, 0.3],
  })
  const disappearing_coords = useHVPosition({
    x: [1, 1],
    y: [1.2, 1.3],
  })

  const node_ref = useRef()

  useEffect(() => {
    switch (state) {
      case S.APPEARING:
        new Konva.Tween({
          node: node_ref.current,
          duration: 0.5,
          x,

          easing: Konva.Easings.BackEaseOut,
        }).play()
        break

      case S.DISAPPEARING:
        new Konva.Tween({
          node: node_ref.current,
          duration: 0.75,
          x: disappearing_coords.x,
          y: correct ? y : disappearing_coords.y,
          rotation: correct ? 0 : 180,

          easing: Konva.Easings.BackEaseIn,
          onFinish: () => {
            node_ref.current.x(appearing_coords.x)
            node_ref.current.y(y)
            node_ref.current.rotation(0)
          },
        }).play()
        break

      default:
        break
    }
    // eslint-disable-next-line
  }, [state, correct])

  return (
    <Group id="TrueFalseAnswer" ref={(node) => (node_ref.current = node)} x={x} y={y}>
      <Rect
        x={0}
        y={0}
        id="answer-bg"
        fill={COLORS.MAIN}
        stroke={COLORS.BRIGHT}
        strokeWidth={width / 100}
        width={width}
        height={height}
      />
      <Text
        x={0.05 * width}
        y={0.05 * height}
        id="answer-text"
        align="center"
        verticalAlign="middle"
        fill={COLORS.WHITE}
        fontFamily="Lato"
        text={answer.content}
        fontSize={40}
        lineHeight={1.25}
        width={0.9 * width}
        height={0.9 * height}
      />
    </Group>
  )
}

export default TrueFalseAnswer
