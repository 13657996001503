import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './css/SummaryScreen.scss';
import Card from "components/Card/Card";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import Button from "components/Button/Button";
import {defaultTo} from "lodash";
import {Translation, withTranslation} from "react-i18next";

const ANIMATION_SPEED = 1000;

class SummaryScreen extends Component {
  static propTypes = {
    data: PropTypes.array,
    fullPoints: PropTypes.number,
    maxPoints: PropTypes.number,
    canRepeatExercises: PropTypes.bool,
    calculatePercentage: PropTypes.bool,
    percentageToPass: PropTypes.number,

    parameters: PropTypes.object,

    replayAction: PropTypes.func,
    nextAction: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    fullPoints: 0,
    maxPoints: 0,
    canRepeatExercises: true,

    replayAction: () => {},
    nextAction: () => {},

    calculatePercentage: false,
    percentageToPass: 50,
  };

  fullPoints = 0
  maxPoints = 0
  hidePoints = false;

  constructor(props) {
    super(props);

    const ignoredExercises = defaultTo(props.parameters["ignored_exercises"], []);
    this.hidePoints = defaultTo(props.parameters["hidePoints"], false);
    let feedback = props.parameters["feedback"];

    this.fullPoints = Object.values(props.data).reduce((previous, current, index) => {
      if (ignoredExercises.includes(index)) {
        return previous;
      } else {
        return previous + parseInt(current.points, 10)
      }
    }, 0);
    this.maxPoints = Object.values(props.data).reduce((previous, current, index) => {
      if (ignoredExercises.includes(index)) {
        return previous;
      } else {
        return previous + parseInt(current.maxPoints, 10)
      }
    }, 0);

    if (feedback) {
      const percentage = Math.round((this.fullPoints / this.maxPoints) * 100);
      for (let [threshold, message] of Object.entries(feedback)) {
        threshold = parseInt(threshold, 10);
        if (percentage >= threshold) {
          feedback = message;
        }
      }
    }

    this.state = {
      visible: true,

      ignoredExercises,
      feedback,
    };
  }

  goNext = (action, levelNumber) => {
    if (this.state.visible) {
      this.setState({
        visible: false,
      });

      if (action === 'next')
        setTimeout(this.props.nextAction, ANIMATION_SPEED);
      else if (action === 'replay')
        setTimeout(this.props.replayAction, ANIMATION_SPEED, levelNumber);
    }
  };

  render() {
    const {calculatePercentage, percentageToPass, canRepeatExercises, t} = this.props;
    const {ignoredExercises, feedback} = this.state;

    const percentage = Math.round((this.fullPoints / this.maxPoints) * 100);
    const didPass = percentage >= percentageToPass;
    const lotOfResults = this.props.data.length > 4;

    let summary = this.props.data.map((levelInfo, index) => {
      if (ignoredExercises.includes(index)) {
        return '';
      }

      if (parseInt(levelInfo.points) === 0 && parseInt(levelInfo.maxPoints) === 0) {
        return '';
      }

      return (
        <Card className="summary-row" key={index}>
          <Card className="score">
            <p className="name">
              { levelInfo.name }
            </p>
            <p className="points">
              { levelInfo.points }{levelInfo.maxPoints > 0 ? ' / ' + levelInfo.maxPoints : ''}
            </p>
          </Card>
          {canRepeatExercises &&
            <Button onClick={this.goNext.bind(this, 'replay', levelInfo.levelNumber)}>
              {t("repeatExercise")}
            </Button>
          }
        </Card>
      );
    });

    return (
      <AnimatedElement className={classNames("SummaryScreen", {"compact": lotOfResults})} visible={this.state.visible}>
        <Translation ns={["summary", "common"]}>
          {t => <>
            {(canRepeatExercises || !calculatePercentage) &&
            <div className="summary scrollable">
              {summary}
            </div>
            }
            {this.maxPoints > 0 &&
              <Card className={'score-total'} color={Card.COLORS.BRIGHT}>
                <p className="name">
                  {t("total")}:
                </p>
                <p className="points">
                  {this.fullPoints} / {this.maxPoints}
                </p>
              </Card>
            }
            { this.maxPoints > 0 && calculatePercentage &&
              <Card className="percentage-total" color={didPass ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}>
                <p className="percentage-count">
                  Twój wynik to {percentage}% punktów.
                </p>
                <p className="pass-info">
                  {didPass ? "Test zaliczony. Brawo!" : "Test do poprawy. Przygotuj się lepiej."}
                </p>
              </Card>
            }
            { feedback &&
            <Card className="percentage-total" color={percentage >= 50 ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}>
              <p className="percentage-count">
                {
                  t("growthMindsetSummary", {percentage})
                }
              </p>
              <p className="pass-info">
                {feedback}
              </p>
            </Card>
            }
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Button onClick={this.goNext.bind(this, 'next')} big>
                  {t("finish")}
                </Button>
              </div>
            </div>
          </>}
        </Translation>
      </AnimatedElement>
    );
  }
}

export default withTranslation(["common"])(SummaryScreen);
