import React from "react"

import "./CleaningExercises.scss"
import { Group, Layer } from "react-konva"
import CleaningAnswer from "./subcomponents/CleaningAnswer"
import FullStage from "../../../components/FullStage"
import Element from "../../../components/Element"
import { ANSWERS_STATES as AS } from "../../../@exercises/Exercise"

const CleaningExercise = ({ inAnswersState, answers, answerChosen, resetTimestamp }) => {
  return (
    <div className="CleaningExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage>
          <Layer>
            <Group>
              {answers?.map((answer, index) => (
                <CleaningAnswer
                  key={answer.id}
                  answer={answer}
                  order={index}
                  answerCount={answers?.length}
                  answerChosen={answerChosen}
                  answerAppeared={resetTimestamp}
                />
              ))}
            </Group>
          </Layer>
        </FullStage>
      </Element>
    </div>
  )
}

export default CleaningExercise
