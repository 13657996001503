import React  from 'react';

import './css/SprintBar.scss';
import {useTranslation} from "react-i18next";

import {ReactComponent as LogoSVG} from "images/logo-white.svg"

function SprintBar({
  points = 0,
  maxPoints = 100,
  level = 1,
  maxLevels = 1,
  parameters = {},

  // helpClick,
  // skipClick,
}) {
  const {t} = useTranslation("common");

  const {hidePoints=false} = parameters;

  return (
    <div className="SprintBar">
      <div className="points"><p>{!hidePoints && <>{t("sprintScore")}: <span className="value">{points} / {maxPoints}</span></>}</p></div>
      <div className="levels"><p><strong>{level} / {maxLevels}</strong></p></div>
      <div className="logo">
        <div className="logo-svg">
          <LogoSVG alt="SprinTECH Learning" />
        </div>
      </div>
    </div>
  );
}

export default SprintBar;
