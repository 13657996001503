import { useMemo } from "react"
import useEffectOnce from "react-use/lib/useEffectOnce"
import create from "zustand"

const DEFAULT_STATE = {
  enabled: true,
  current: 0,
  max: 0,
}

export const usePointsState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),

  changePoints: (change) =>
    set((s) => ({
      current: s.current + change,
    })),
}))

export const usePoints = (config) => {
  const { enabled, current, max, resetState, changePoints } = usePointsState()
  const readable = useMemo(() => `${current}${max ? ` | ${max}` : ""}`, [current, max])

  useEffectOnce(() => {
    const { enabled: points_enabled } = config.parameters.points

    resetState({
      enabled: points_enabled,
      current: 0,
      max: config.maxPoints(),
    })

    return () => resetState()
  })

  return {
    enabled,

    current,
    max,
    readable,

    changePoints,
  }
}
