import React from "react"
import { Group, Line } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import ButtonK from "../../../@konva/ui/ButtonK"

const ConversationChoice = ({ position, content, onClick }) => {
  const [x, y, width, height] = usePositions([
    [0.29, 0.19],
    [0.35 + position * 0.16, 0.25 + position * 0.11],

    [0.7, 0.8],
    [0.12, 0.08],
  ])
  const [x1, y1, x2, y2, x3, y3] = usePositions([
    [0.66, 0.75],
    [0, 0],

    [0.69, 0.78],
    [0, 0],

    [0.698, 0.798],
    [0.03, 0.02],
  ])

  return (
    <Group x={x} y={y} id="ConversationStarter">
      <Line
        x={0}
        y={0.98 * height}
        fill={COLORS.CLICKABLE}
        points={[x1, y1, x2, y2, x3, y3]}
        closed={true}
      />
      <ButtonK
        width={width}
        height={height}
        rectProps={{ cornerRadius: 0.1 * height }}
        onClick={onClick}
        text={content}
        size={"sm"}
      />
    </Group>
  )
}

export default ConversationChoice
