import React from "react"
import { Group } from "react-konva"
import ConversationChoice from "./ConversationChoice"

const ConversationChoices = ({ answers, answerChosen }) => {
  return (
    <Group id="ConversationChoices">
      {answers.map((answer, index) => (
        <ConversationChoice
          key={answer.id}
          position={index}
          content={answer.content}
          onClick={() => answerChosen(answer)}
        />
      ))}
    </Group>
  )
}

export default ConversationChoices
