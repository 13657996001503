import React, { useState } from "react"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import FullStage from "../../components/FullStage"
import { Group, Layer } from "react-konva"
import Element from "../../components/Element"
import ConversationStarter from "./subcomponents/ConversationStarter"
import ConversationAnswer from "./subcomponents/ConversationAnswer"
import ConversationChoices from "./subcomponents/ConversationChoices"

const ConversationsExercise = ({ inAnswersState, question, answers, answerChosen }) => {
  const [lastAnswer, setLastAnswer] = useState("")

  return (
    <div className="ConversationsExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage>
          <Layer>
            <Group>
              <ConversationStarter content={question.content} />
              <ConversationAnswer answer={lastAnswer} setAnswer={setLastAnswer} />
              <ConversationChoices answers={answers} answerChosen={answerChosen} />
            </Group>
          </Layer>
        </FullStage>
      </Element>
    </div>
  )
}

export default ConversationsExercise
