import imgHorizontal from "images/exercises/bubbles/exercise-horizontal.jpg"
import imgVertical from "images/exercises/bubbles/exercise-vertical.jpg"
import { SIDES } from "../../state/$sprint"
import { QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"

const cleaning_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    sides: {
      [SIDES.TOP]: true,
      [SIDES.BOTTOM]: true,
      [SIDES.LEFT]: true,
      [SIDES.RIGHT]: true,
    },
    answers: {
      finish_type: QUESTION_FINISH_TYPES.ALL_CORRECT,
    },
    points: {
      per_correct_answer: 2,
      per_incorrect_answer: -1,
    },
  },
}

export default cleaning_config
