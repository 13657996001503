import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { Group } from "react-konva"

import useHVPosition from "hooks/screen/useHVPosition"
import ButtonK from "@konva/ui/ButtonK"
import Konva from "konva"
import {
  PUZZLE_IMAGE_HEIGHT,
  PUZZLE_IMAGE_WIDTH,
  PUZZLE_IMAGE_X,
  PUZZLE_IMAGE_Y,
} from "./PuzzleImage"
import emojiRegex from "emoji-regex"

export const PUZZLE_WIDTH = [0.23, 0.23]
export const PUZZLE_HEIGHT = [0.16, 0.1]

const PuzzleAnswer = ({ answer, answered, order, count, setDraggedAnswer }) => {
  const position = useMemo(() => order / count, [order, count])
  const isEmoji = useMemo(() => !!emojiRegex().exec(answer.content), [answer.content])
  const node_ref = useRef()
  const tween_ref = useRef()

  const { height: stage_height } = useHVPosition({ height: [0.9, 0.9] })
  const { x, y, width, height } = useHVPosition({
    x: [position + 1 / (2 * count), position + 1 / (2 * count)],
    y: [0.9, 0.85],
    width: PUZZLE_WIDTH,
    height: PUZZLE_HEIGHT,
  })
  const { x: missing_piece_x, y: missing_piece_y } = useHVPosition({
    x: [
      PUZZLE_IMAGE_X[0] + PUZZLE_IMAGE_WIDTH[0] / 2,
      PUZZLE_IMAGE_X[1] + PUZZLE_IMAGE_WIDTH[1] / 2,
    ],
    y: [
      PUZZLE_IMAGE_Y[0] + PUZZLE_IMAGE_HEIGHT[0] / 2,
      PUZZLE_IMAGE_Y[1] + PUZZLE_IMAGE_HEIGHT[1] / 2,
    ],
  })

  const returnPuzzle = useCallback(() => {
    if (answer.answered) return

    tween_ref.current = new Konva.Tween({
      node: node_ref.current,
      duration: 0.5,
      x,
      y,
      easing: Konva.Easings.ElasticEaseOut,
    })
    tween_ref.current.play()
  }, [x, y, answer.answered])

  const success = useCallback(() => {
    tween_ref.current = new Konva.Tween({
      node: node_ref.current,
      duration: 0.5,
      x: missing_piece_x,
      y: missing_piece_y,
      // scaleX: 1.25,
      // scaleY: 1.25,

      easing: Konva.Easings.ElasticEaseOut,
      onFinish: () => {
        new Konva.Tween({
          node: node_ref.current,
          duration: 0.25,
          scaleX: 0.8,
          scaleY: 0.8,
          opacity: 0,

          easing: Konva.Easings.EaseInOut,
        }).play()
      },
    })
    tween_ref.current.play()
  }, [missing_piece_x, missing_piece_y])

  const failure = useCallback(() => {
    tween_ref.current = new Konva.Tween({
      node: node_ref.current,
      duration: 1.5,

      rotation: 360,
      scaleX: 0,
      scaleY: 0,

      x,
      y: stage_height * 1.2,

      easing: Konva.Easings.BackEaseInOut,
    })
    tween_ref.current.play()
  }, [stage_height, x])

  useEffect(() => {
    if (answered) {
      if (answer.correct) success()
      else failure()
    }
  }, [answered, answer.correct, success, failure])

  return (
    <Group
      id={`PuzzleAnswer${order}`}
      ref={(node) => (node_ref.current = node)}
      x={x}
      y={y}
      offsetX={width / 2}
      offsetY={height / 2}
      draggable={true}
      onDragStart={() => setDraggedAnswer(answer)}
      onDragEnd={returnPuzzle}
    >
      <ButtonK
        width={width}
        height={height}
        text={answer.content}
        size={isEmoji ? "lg" : "md"}
        // onClick={() => answerChosen(answer)}
      />
    </Group>
  )
}

export default PuzzleAnswer
