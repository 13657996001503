import React, {Component} from 'react'

import './App.scss';
import 'text-security/dist/text-security.css';

import Sprint from "./Sprint";
import {Route, BrowserRouter} from "react-router-dom";


class App extends Component {
  render() {
    return (
      <div className="App">
          <BrowserRouter>
            <Route path={`/:instanceName?/:sessionId?`} component={Sprint}/>
          </BrowserRouter>
      </div>
    );
  }
}

export default App;
