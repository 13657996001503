import CleaningExercise from "../components/CleaningExercise"
import PuzzleExercise from "../puzzle/PuzzleExercise"
import TrueFalseExercise from "../true_false/TrueFalseExercise"
import BubblesExercise from "../bubbles/BubblesExercise"
import ConversationsExercise from "../conversations/ConversationsExercise"

const EXERCISE_COMPONENTS = {
  cleaning: CleaningExercise,
  puzzle: PuzzleExercise,
  true_false: TrueFalseExercise,
  bubbles: BubblesExercise,
  conversations: ConversationsExercise,
}

export default EXERCISE_COMPONENTS
